// Layout.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import SideDrawer from './SideDrawer'; // Import your drawer component
// import SideDrawer from './sidedrawerExp'; // Import your drawer component
import Dashboard from './Dashboard'; // Import your Dashboard component
import ManuscriptReadiness from './ManuscriptReadiness'; // Import your ManuscriptReadiness component
import Reports from './Reports'; // Import your Reports component
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './style.css';

const drawerWidth = '220px';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth})`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Layout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    // <div style={{ display: 'flex' }}>
    //   <CssBaseline />
    //   <AppBarStyled position="fixed" open={open}>
    //     <Toolbar>
    //       <IconButton
    //         color="inherit"
    //         aria-label="open drawer"
    //         onClick={handleDrawerOpen}
    //         edge="start"
    //         sx={{ mr: 2, ...(open && { display: 'none' }) }}
    //       >
    //         <MenuIcon />
    //       </IconButton>
    //     </Toolbar>
    //   </AppBarStyled>
    //   <SideDrawer handleDrawerClose={handleDrawerClose} open={open} />
    //   <Main open={open}>
    //     <DrawerHeader />
    //     <Routes>
    //     <Route path="/dashboard" element={<Dashboard />} />
    //     <Route path="/manuscript-readiness" element={<ManuscriptReadiness />} />
    //     <Route path="/reports" element={<Reports />} />
    //     {/* Other routes */}
    //   </Routes>
    //   </Main>
    // </div>
    <div className='wholeWrap'>
        {/* <ManuscriptReadiness/> */}
        <Router>
    <SideDrawer />
  </Router>,
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
