import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function ResponsiveDialog({ open, confirm, cancel }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={cancel}
        aria-labelledby="responsive-dialog-title"
        className="modelPopup"
        style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      >
        <DialogTitle className="content_popup_delete">
          <div className="heading_popup">
            {"Are you sure you want to Exit?"}
          </div>
        </DialogTitle>
        <DialogContent className="content_popup_delete">
          <div className="heading_popup">
            <p className="para_popup">You can redirect to the login page</p>
          </div>
        </DialogContent>
        <DialogActions className="button_popup">
          <Button autoFocus onClick={confirm} className="closeConfirmButton" style={{ marginRight: '10px', backgroundColor: "#C62026FF" }}>
            Yes
          </Button>
          <Button onClick={cancel} autoFocus className="closeButtonPopup">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResponsiveDialog;
