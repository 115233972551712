import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from './components/Layout';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const [authenticationChecked, setAuthenticationChecked] = useState(false);
  const [count, setCount] = useState(0);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const authenticateUser = async () => {
      if (!isAuthenticated && !isLoading) {
        try {
          await loginWithRedirect();
        } catch (error) {
          console.error('Authentication error:', error);
        }
      } else if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          const aiEndPoint = 'https://quixl.integra.co.in/v1/users/remote';
          const response = await axios.get(aiEndPoint, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.data) {
            localStorage.setItem('isAuthenticatedValue', true);
            localStorage.setItem('userData', JSON.stringify(response.data));
            setAuthenticationChecked(true); // Set authentication check flag
          } else {
            localStorage.setItem('isAuthenticatedValue', false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          logout();
        }
      }
    };

    authenticateUser();
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  useEffect(() => {
    const handleActivity = () => {
      // Update the last activity time whenever there's user activity
      setLastActivityTime(Date.now());
    };

    // Attach event listeners to track user activity
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);

    return () => {
      // Remove event listeners when the component unmounts
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };
  }, []);

  useEffect(() => {
    // Check token expiration every minute
    const tokenExpirationInterval = setInterval(() => {
      checkTokenExpiration();
      setCount(count + 1)
    }, 58 * 60 * 1000); // 1 hour in milliseconds

    // Clear the interval when the component unmounts
    return () => clearInterval(tokenExpirationInterval);
  }, [count]);

  const checkTokenExpiration = async () => {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }
        else {
          // const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
          // const currentTime = Math.floor(Date.now() / 1000);
          // if (tokenPayload.exp < currentTime) {
          // console.log('Token is expired');
          logout();
        }
      } catch (error) {
        console.error('Error checking token expiration:', error);
      }
      const currentTimeVal = Date.now();
      const inactivityDuration = currentTimeVal - lastActivityTime;
      // Set the inactivity threshold
      const inactivityThreshold = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      if (inactivityDuration > inactivityThreshold) {
        console.log('User has been inactive. Logging out...');
        logout();
      }
    }
  };

  if (isLoading || !authenticationChecked) {
    return <div>Loading...</div>; // Render a loading indicator while authentication is in progress or authentication check is not completed
  }

  if (!isAuthenticated) {
    return null; // Render nothing if user is not authenticated
  }

  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
