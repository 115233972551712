
import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputAdornment, TextField, TablePagination, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Button,
    Divider,
    useMediaQuery, InputLabel
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { saveAs } from 'file-saver';
import infoIcon from '../icons/info.svg';
import closeIcon from '../icons/close.svg';
import viewIcon from '../icons/view.svg';
import pdfDownloadIcon from '../icons/pdf.svg';
import pdfGenerateIcon from '../icons/pdfGenerate.svg';
import editIcon from '../icons/edit.svg';
import wordIcon from '../icons/word.svg';
import spiralIcon from '../icons/spiral.svg';

import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const TableSection = ({ loading, dbRespData, updateIntensiveComplete, intensiveCompleteMap, updateMlaComplete, mlaCompleteMap, updateTarComplete, tarCompleteMap }) => {
    // const [filteredData, setFilteredData] = useState([]); // Filtered data based on search
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    let userDataJson = JSON.parse(localStorage.getItem("userData"));
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [inprogressData, setInprogressData] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [sortField, setSortField] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [mlaSpinningIcons, setMlaSpinningIcons] = useState({});
    const [tarSpinningIcons, setTarSpinningIcons] = useState({});
    const [spinningIntensiveIcons, setSpinningIntensiveIcons] = useState({});
    const [langProInprogressSnackbar, setLangProInprogressSnackbar] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const textFieldRef = useRef(null);
    const navigate = useNavigate('');

    // Retrieve state from localStorage
    useEffect(() => {
        const savedLangPro = JSON.parse(localStorage.getItem("langpro"));
        if (savedLangPro) {
            setInprogressData(savedLangPro);
        }

        const savedMlaSpinningIcons = JSON.parse(localStorage.getItem("mlaSpinningIcons"));
        if (savedMlaSpinningIcons) {
            setMlaSpinningIcons(savedMlaSpinningIcons);
        }

        const savedTarSpinningIcons = JSON.parse(localStorage.getItem("tarSpinningIcons"));
        if (savedTarSpinningIcons) {
            setTarSpinningIcons(savedTarSpinningIcons);
        }

        const savedSpinningIntensiveIcons = JSON.parse(localStorage.getItem("spinningIntensiveIcons"));
        if (savedSpinningIntensiveIcons) {
            setSpinningIntensiveIcons(savedSpinningIntensiveIcons);
        }
    }, []);

    // Save state to localStorage
    useEffect(() => {
        localStorage.setItem("langpro", JSON.stringify(inprogressData));
    }, [inprogressData]);

    useEffect(() => {
        localStorage.setItem("mlaSpinningIcons", JSON.stringify(mlaSpinningIcons));
    }, [mlaSpinningIcons]);

    useEffect(() => {
        localStorage.setItem("tarSpinningIcons", JSON.stringify(tarSpinningIcons));
    }, [tarSpinningIcons]);

    useEffect(() => {
        localStorage.setItem("spinningIntensiveIcons", JSON.stringify(spinningIntensiveIcons));
    }, [spinningIntensiveIcons]);

    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.blur();
        }
        setPage(0);
    }, [searchTerm]);

    const filteredData = useMemo(() => {
        // Apply sorting
        let sortedData = [...dbRespData];
        if (sortField) {
            sortedData.sort((a, b) => {
                const aValue = a[sortField];
                const bValue = b[sortField];
                if (sortDirection === 'asc') {
                    return aValue > bValue ? 1 : -1;
                } else {
                    return aValue < bValue ? 1 : -1;
                }
            });
        }
        return sortedData.filter(item => {
            if (!item) return false;
            return Object.values(item).some(value => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
        });
    }, [dbRespData, sortField, sortDirection, searchTerm]);
    // }, [dbRespData]);
    localStorage.setItem("UserName", userDataJson.userName);
    localStorage.setItem("tenantName", userDataJson.tenantName.toLowerCase());
    let tenantCheckName = localStorage.getItem("tenantName");
    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    function getToday() {
        try {
            var month = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var today = new Date();
            var fday = {
                year: '',
                month: '',
                date: '',
                time: '',
                datetime: ''
            };
            //var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            fday.year = '' + yyyy;
            fday.month = month[mm - 1] + '_' + yyyy;
            dd = (dd < 10) ? '0' + dd : dd;
            mm = (mm < 10) ? '0' + mm : mm;
            fday.date = dd + '-' + mm + '-' + yyyy;
            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            fday.time = hours + ':' + minutes + ' ' + ampm;
            fday.datetime = fday.date + ' ' + fday.time;
            return fday;
        } catch (error) {
            console.debug(error);
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = field => {
        if (sortField === field) {
            // Toggle between ascending, descending, and no sorting
            if (sortDirection == 'asc') {
                setSortDirection('');
                setSortField('');
            } else if (sortDirection == 'desc') {
                setSortDirection('');
                setSortField('');
            } else {
                setSortDirection('asc');
            }
        } else {
            // If sorting by a different field, set the sort field and direction
            setSortField(field);
            setSortDirection('asc');
        }
    };
    const handleInfoClickOpen = () => {
        setOpen(true);
    };

    const handleInfoClose = () => {
        setOpen(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };
    const intensiveProcessClick = async (processID, fileName) => {
        setSpinningIntensiveIcons(prevState => ({ ...prevState, [processID]: true }));
        let cleanedFileName = fileName.replace(/\.docx$/, '');
        let userProfileVal = localStorage.getItem("UserName");
        let tenantName = localStorage.getItem("tenantName");
        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
        //     processID: processID,
        //     fileName: cleanedFileName,
        //     fileSize: '10mb',
        //     bookCode: 'JOUR',
        //     langDialect: 'en-author',
        //     userName: userProfileVal,
        //     copyEditLevel: 'intensiveEdit',
        //     customer: tenantName||'internal',
        //     fileContent: 'iMLA_INPUT',
        //     time: getToday().datetime
        // }, { withCredentials: true });
        setLangProInprogressSnackbar(true);
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
            processID: processID,
            fileName: cleanedFileName,
            fileSize: '10mb',
            bookCode: 'JOUR',
            langDialect: 'en-author',
            userName: userProfileVal,
            copyEditLevel: 'intensiveEdit',
            customer: tenantName || 'internal',
            fileContent: 'iMLA_INPUT',
            time: getToday().datetime
        });
        if (response.status == 200) {
            // Call the callback function in the parent component to update its state
            updateIntensiveComplete(processID, 1);
            setSpinningIntensiveIcons(prevState => ({ ...prevState, [processID]: false }));
        }
        else {
            updateIntensiveComplete(processID, -1);
        }
    }
    const viewMlaHtmlReportClick = async (processID, fileName, reportType) => {
        let cleanedFileName = fileName.replace(/\.docx$/, '');
        let urlPath = '';
        if (reportType == 'mla') {
            urlPath = processID + "/" + cleanedFileName + ".html"
        }
        else {
            urlPath = processID + "/" + cleanedFileName + "_autostructure.html"
        }

        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchFile', {
        //     processId: processID,
        //     fileDownName: cleanedFileName,
        //     downType: 'HtmlDown',
        //     reportType: reportType,
        //     url: urlPath
        // }, { withCredentials: true });
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchFile', {
            processId: processID,
            fileDownName: cleanedFileName,
            downType: 'HtmlDown',
            reportType: reportType,
            url: urlPath
        });
        let serviceRes = response.data;
        let respbase64 = arrayBufferToBase64(serviceRes.data)
        const htmlString = base64ToHtml(respbase64);
        // Redirect to Reports page
        if (reportType == 'mla') {
            navigate('/reports', {
                state: {
                    htmlString,
                    assessmentType: "lar",  // Pass the assessment type
                    journalCode: "JOUN1231691745951692",        // Pass the journal code
                    articleId: processID
                }
            });
        } else {
            navigate('/reports', {
                state: {
                    htmlString,
                    assessmentType: "tar",  // Pass the assessment type
                    journalCode: "JOUN1231691745951692",        // Pass the journal code
                    articleId: processID
                }
            });
        }

        // history.push('/reports')
        // let serviceRes = response.data;
        // let serviceResult = serviceRes.blobData;
        // const arrayBuffer = serviceResult.data;
        // let link = document.createElement('a');
        // link.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + arrayBufferToBase64(arrayBuffer) + "";
        // link.download = cleanedFileName + "_track" + '.docx';
        // link.click();
    }
    const base64ToHtml = (base64String) => {
        return atob(base64String);
    };
    const handlePdfDownloadClick = async (processID, fileName, clickVal) => {
        // Set the spinning state for the specific row to true
        let reportTypeValue = '';
        if (clickVal == 'tarPdf') {
            setTarSpinningIcons(prevState => ({ ...prevState, [processID]: true }));
            reportTypeValue = 'tar';
        } else {
            setMlaSpinningIcons(prevState => ({ ...prevState, [processID]: true }));
            reportTypeValue = 'mla';
        }

        const cleanedFileName = fileName.replace(/\.docx$/, '');
        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/getPdf', {
        //     ProcessID: processID,
        //     FileName: cleanedFileName,
        //     reportTypeVal: reportTypeValue
        // }, { responseType: 'arraybuffer', withCredentials: true });
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/getPdf', {
            ProcessID: processID,
            FileName: cleanedFileName,
            reportTypeVal: reportTypeValue
        }, { responseType: 'arraybuffer' });
        const arrayBuffer = response.data;
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        const dataUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', cleanedFileName + ".pdf")
        document.body.appendChild(link)
        link.click();
        window.open(dataUrl);
        if (response.status == 200 && clickVal == 'tarPdf') {
            setTarSpinningIcons(prevState => ({ ...prevState, [processID]: true }));
            updateTarComplete(processID, 1)
        }
        else if (response.status == 200 && clickVal == 'mlaPdf') {
            setMlaSpinningIcons(prevState => ({ ...prevState, [processID]: true }));
            updateMlaComplete(processID, 1)
        }
    };
    const intensiveEditDownClick = async (processID, fileName) => {
        const cleanedFileName = fileName.replace(/\.docx$/, '');
        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchInlpWordFile', {
        //     processId: processID,
        //     fileDownName: cleanedFileName,
        //     downType: 'trackDown'
        // }, { withCredentials: true });
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchInlpWordFile', {
            processId: processID,
            fileDownName: cleanedFileName,
            downType: 'trackDown'
        });
        let serviceRes = response.data;
        let serviceResult = serviceRes.blobData;
        let serviceResult_cc = serviceRes.blobData_cc;
        const arrayBuffer = serviceResult.data;
        const arrayBuffer_cc = serviceResult_cc.data;
        let link = document.createElement('a');
        link.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + arrayBufferToBase64(arrayBuffer) + "";
        link.download = cleanedFileName + "_track" + '.docx';
        link.click();
        // let link_cc = document.createElement('a');
        // link_cc.href = "data:text/html;base64," +  arrayBufferToBase64(arrayBuffer_cc) + "";
        // link_cc.download = cleanedFileName + "_ccReport" + '.html';
        // link_cc.click();
    }
    return (
        <Paper className="tableSectionWrap">
            <TextField
                id="outlined-basic"
                variant="outlined"
                className="searchtextFieldClass"
                value={searchTerm}
                onChange={(e) => {
                    const inputValue = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive search
                    setSearchTerm(inputValue);
                }}
                InputLabelProps={{
                    shrink: searchTerm !== '' || isFocused,
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                label="Search by Process ID"
                onFocus={handleFocus}
                onBlur={() => setIsFocused(false)}
            />
            <TableContainer>
                <Table className="dispTable">
                    <TableHead>
                        <TableRow className="dispTableRow">
                            <TableCell className="tableHeadName">SL.NO</TableCell>
                            {/* <TableCell className="tableHeadName" onClick={(e) => { e.stopPropagation(); handleSort('fileName') }}>Article Title</TableCell> */}
                            <TableCell className="tableHeadName fileNameHeader" onClick={(e) => { e.stopPropagation(); handleSort('fileName') }}>{(
                                sortDirection == 'asc' ? (
                                    <ArrowUpwardIcon />
                                ) : (
                                    <ArrowDownwardIcon />
                                )
                            )}Article Title</TableCell>
                            {/* <TableCell className="tableHeadName" onClick={(e) => { e.stopPropagation(); handleSort('tar') }}>Technical Assessment Report <img src={infoIcon} alt="Info Icon" className='infoIcon' onClick={(e) => { e.stopPropagation(); handleInfoClickOpen() }} /></TableCell> */}
                            <TableCell className="tableHeadName">Technical Assessment Result <img src={infoIcon} alt="Info Icon" className='infoIcon' /></TableCell>
                            <TableCell className="tableHeadName">Language Assessment Result <img src={infoIcon} alt="Info Icon" className='infoIcon' onClick={handleInfoClickOpen} /></TableCell>
                            {tenantCheckName == 'inlptenant' ? (``) : (<><TableCell className="tableHeadName">Lang Pro</TableCell></>)}
                        </TableRow>
                    </TableHead>
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleInfoClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Scoring"}
                            <span className="closeIcon">
                                <span><img src={closeIcon} onClick={handleInfoClose} alt="Close Icon" /></span>
                            </span>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <DialogContentText>
                                <span>
                                    <p><span className="passSent">Pass</span><span className="passReas"> Quality score greater than or equal to 77%</span></p>
                                    <p><span className="failSent">Fail</span><span className="failReas">Quality score lesser than 77%</span></p>
                                </span>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            const rowIndex = page * rowsPerPage + index + 1;
                            return (
                                <TableRow key={row.processID}>
                                    <TableCell className="tableCellClass">{index + 1}</TableCell>
                                    <TableCell className="tableHeadName tableCellClass artiTableClass" onClick={(e) => { e.stopPropagation(); }}>{<div class="artiName"><span className="artiFilename">{row.FileName}</span><span className="artiProcessID">ID - {row.processID}</span></div>}</TableCell>
                                    <TableCell className="tableCellClass">
                                        <div className="mlaRepWrapTech">
                                            {row.failed === 1 ? (
                                                <span className="failedText">FAILED</span>
                                            ) : (row.status === 'inprogress' || ['0', '1', '2'].includes(row.status)) ? (
                                                <div className="mlaRepProgressWrap">
                                                    <span className="spiralIcon"><img src={spiralIcon} alt="spiral Icon" /></span>
                                                    <span className="inprogressText">IN PROGRESS</span>
                                                </div>
                                            ) : row.techReportStatus === 1 ? (
                                                <span className="completedText">COMPLETED</span>
                                            ) : (
                                                <>
                                                    <span className="reportView" onClick={() => viewMlaHtmlReportClick(row.processID, row.FileName, 'tar')}>
                                                        <img src={viewIcon} alt="ViewIcon" title="View technical report" />
                                                    </span>
                                                    {row.techReportStatus == "2" || tarCompleteMap[row.processID] == 1 ? (
                                                        <span
                                                            className="pdfDownIcon"
                                                            onClick={() => handlePdfDownloadClick(row.processID, row.FileName, 'tarPdf')}
                                                        >
                                                            <img src={pdfDownloadIcon} alt="pdf Icon" title="Download Technical Report" />
                                                        </span>
                                                    ) : (
                                                        tarSpinningIcons[row.processID] ? (
                                                            // Show spinning icon if the spinning state is true for this row
                                                            <div className="spiralIcon"><img src={spiralIcon} alt="spiral Icon" /></div>) :
                                                            (<span
                                                                className="pdfDownIcon"
                                                                onClick={() => handlePdfDownloadClick(row.processID, row.FileName, 'tarPdf')}
                                                            >
                                                                <img src={pdfGenerateIcon} alt="pdf Icon" title="Generate Technical Report" />
                                                            </span>)
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className="tableCellClass">
                                        <div className="mlaRepWrap">
                                            {row.failed === 1 ? (
                                                <span className="failedText">FAILED</span>
                                            ) : (row.status === 'inprogress' || ['0', '1', '2'].includes(row.status)) ? (
                                                <div className="mlaRepProgressWrap">
                                                    <span className="spiralIcon"><img src={spiralIcon} alt="spiral Icon" /></span>
                                                    <span className="inprogressText">IN PROGRESS</span>
                                                </div>
                                            ) : row.status === 3 ? (
                                                <span className="completedText">COMPLETED</span>
                                            ) : (
                                                <>
                                                    <span className={row.quality > 77 ? "mlaPercPass" : "mlaPercFail"}>
                                                        {row.quality}%
                                                    </span>
                                                    <span className="reportView" onClick={() => viewMlaHtmlReportClick(row.processID, row.FileName, 'mla')}>
                                                        <img src={viewIcon} alt="ViewIcon" title="View Language report" />
                                                    </span>
                                                    {row.status === "4" || mlaCompleteMap[row.processID] == 1 ? (
                                                        // Show spinning icon if the spinning state is true for this row
                                                        <span
                                                            className="pdfDownIcon"
                                                            onClick={() => handlePdfDownloadClick(row.processID, row.FileName, 'mlaPdf')}
                                                        >
                                                            <img src={pdfDownloadIcon} alt="pdf Icon" title="Download Language Report" />
                                                        </span>

                                                    ) : (
                                                        mlaSpinningIcons[row.processID]
                                                            ? (<div className="spiralIcon"><img src={spiralIcon} alt="spiral Icon" /></div>)
                                                            : (<span
                                                                className="pdfDownIcon"
                                                                onClick={() => handlePdfDownloadClick(row.processID, row.FileName, 'mlaPdf')}
                                                            >
                                                                <img src={pdfGenerateIcon} alt="pdf Icon" title="Generate Language Report" />
                                                            </span>)
                                                        // Show PDF icon if the spinning state is false for this row

                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell className="tableCellClass">
                                        {(row.intensiveComplete == 1 || intensiveCompleteMap[row.processID] === 1) ?
                                            (<span className="intensiveEditIcon" onClick={() => intensiveEditDownClick(row.processID, row.FileName)}>
                                                <img src={wordIcon} alt="wordIcon" />
                                            </span>) :
                                            (<span className="intensiveEditIcon" onClick={() => intensiveProcessClick(row.processID, row.FileName)}>
                                                <img src={editIcon} alt="editIcon" />
                                            </span>)}
                                    </TableCell> */}
                                    {tenantCheckName == 'inlptenant' ? (``) : (<>
                                        <TableCell className="tableCellClass">
                                            {(row.intensiveComplete === 1 || intensiveCompleteMap[row.processID] === 1) ? (
                                                <span className="intensiveEditIcon" onClick={() => intensiveEditDownClick(row.processID, row.FileName)}>
                                                    <img src={wordIcon} alt="wordIcon" title="Download Intensive Edit Report" />
                                                </span>
                                            ) : (
                                                (row.intensiveComplete === -1 || intensiveCompleteMap[row.processID] === -1) ? (
                                                    <span className="intensiveEditIcon">
                                                        <span className="failedText">FAILED</span>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {(row.status === 'inprogress' || ['0', '1', '2'].includes(row.status) || row.failed === 1) ? (
                                                            <span className="intensiveEditIcon iconIntensiveDisabled" onClick={() => intensiveProcessClick(row.processID, row.FileName)}>
                                                                {spinningIntensiveIcons[row.processID] ? (
                                                                    // Show spinning icon if the spinning state is true for this row
                                                                    <div>
                                                                        <span className="inprogressText">IN PROGRESS</span></div>
                                                                ) : (
                                                                    // Show edit icon if the spinning state is false for this row
                                                                    <img src={editIcon} alt="editIcon" title="Process Intensive Edit" />
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span className="intensiveEditIcon" onClick={() => intensiveProcessClick(row.processID, row.FileName)}>
                                                                {spinningIntensiveIcons[row.processID] ? (
                                                                    <div>
                                                                        <span className="inprogressText">IN PROGRESS</span>
                                                                    </div>
                                                                ) : (
                                                                    <img src={editIcon} alt="editIcon" title="Process Intensive Edit" />
                                                                )}
                                                            </span>
                                                        )}
                                                    </span>
                                                )
                                            )}
                                        </TableCell></>)}
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <div>
                <div>{ReactHtmlParser(htmlContent)}</div>
            </div> */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={langProInprogressSnackbar}
                autoHideDuration={4000}
                className="langProSnackBar"
                onClose={() => setLangProInprogressSnackbar(false)}
            >
                <MuiAlert
                    variant="filled"
                    className="langProcessSnack"
                    severity="info"
                >
                    Processing might take a few minutes! Please wait or come back to download.
                </MuiAlert>
            </Snackbar>
        </Paper >
    );
};

export default TableSection;
