import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, MenuItem, FormControl, Select } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import moment from 'moment';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';


export default function Dashboard() {
    let userDataJson = JSON.parse(localStorage.getItem("userData"));
    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last quarter',
            value: [subDays(new Date(), 89), new Date()],
            placement: 'left'
        },
    ];

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const fromDate = dates[0];
            const toDate = dates[1];

            FromDateChange(fromDate);
            ToDateChange(toDate);
        }
    };

    const applyandCancel = () => {
        setTimeout(() => {
            let buttons = document.getElementsByClassName('rs-btn rs-btn-primary rs-btn-sm rs-btn-disabled');
            if (buttons.length === 0) {
                buttons = document.getElementsByClassName('rs-btn rs-btn-primary rs-btn-sm');
            }
            if (buttons.length === 1 && buttons[0].innerText === "OK") {
                buttons[0].innerText = "APPLY";

                const cancelButton = document.createElement("button");
                cancelButton.className = "rs-btn rs-btn-default rs-btn-sm";
                cancelButton.innerText = "CANCEL";
                cancelButton.style.marginLeft = "10px";
                cancelButton.style.background = "#fff";
                cancelButton.style.border = "1px solid #e5e5ea";
                cancelButton.style.fontFamily = "'Poppins', sans-serif";
                cancelButton.style.color = "#000";
                cancelButton.style.fontSize = "12px";
                cancelButton.style.fontWeight = "700";

                cancelButton.onclick = () => {
                    const datepicker = document.querySelector('.rs-picker');
                    if (datepicker) {
                        datepicker.click();
                    }
                };

                buttons[0].parentNode.insertBefore(cancelButton, buttons[0].nextSibling);
            }
        }, 50);
    };

    const FromDateChange = (date) => {
        const dateObj = new Date(date);
        const fromDate = moment(new Date(dateObj.toDateString())).format('YYYY-MM-DDTHH:MM:SS');
        setfromdate(String(fromDate));
        // setPageNo(0);
    };

    const clearDateFilter = () => {
        setfromdate(moment('01/01/2023', 'DD/MM/YYYY').format());
        settodate(moment(defaultToDate, 'DD/MM/YYYY').format());
    };

    const ToDateChange = (date) => {
        const dateObj = new Date(date);
        const toDate = moment(new Date(dateObj.toDateString())).format('YYYY-MM-DDTHH:MM:SS');
        settodate(String(toDate));
        // setPageNo(0);
    };
    const currentDate = new Date();
    const defaultToDate = currentDate.toLocaleDateString('en-GB');
    const [fromdate, setfromdate] = useState(moment('01/01/2023', 'DD/MM/YYYY').format());
    const [todate, settodate] = useState(moment(defaultToDate, 'DD/MM/YYYY').format());
    const [dashboardData, setDashboardData] = useState({});
    const [timeRange, setTimeRange] = useState('thisWeek');
    const [dateRange, setDateRange] = useState({ startDate: '4 february 2024', endDate: '10 february 2024' });
    const { loginWithRedirect, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    const { search } = location;
    useEffect(() => {
        const currentDate = new Date();
        const currentDayIndex = currentDate.getDay();
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - currentDayIndex);
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() + (6 - currentDayIndex));
        setDateRange({
            startDate: startDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }),
            endDate: endDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
            // startDate: '4 february 2024',
            // endDate: '10 february 2024'
        });
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/db/getDashboardData', {
                //     userId: userDataJson.userName,
                //     timeRange: timeRange,
                //     fromDate: fromdate, // Add fromDate to the request payload
                //     toDate: todate,     // Add toDate to the request payload
                // }, { withCredentials: true });
                const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/db/getDashboardData', {
                    userId: userDataJson.userName,
                    timeRange: timeRange,
                    fromDate: fromdate, // Add fromDate to the request payload
                    toDate: todate,     // Add toDate to the request payload
                });
                setDashboardData(response.data);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };
        fetchData();
    }, [timeRange, fromdate, todate]); // Include fromDate and toDate in the dependency array


    const handleChangeTimeRange = (event) => {
        setTimeRange(event.target.value);
    };

    return (
        <div>
            <div className="datePickerRangeWrap">
                <Stack direction="column" spacing={8} alignItems="flex-start" style={{ marginRight: "10px" }}>
                    <DateRangePicker
                        ranges={predefinedRanges}
                        showOneCalendar
                        placeholder="Select"
                        placement='bottomEnd'
                        style={{ width: 243, height: 45, fontSize: "14px" }}
                        onShortcutClick={(shortcut) => {
                            console.log(shortcut);
                        }}
                        onChange={handleDateChange}
                        onClick={applyandCancel}
                        onClean={clearDateFilter}
                    />
                </Stack>
            </div>
            <div className="dashboardWrp">
                <h2>Dashboard</h2>
                {/* <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <Select
                    value={timeRange}
                    onChange={handleChangeTimeRange}>
                    <MenuItem value={'thisWeek'}>This Week</MenuItem>
                    <MenuItem value={'thisMonth'}>This Month</MenuItem>
                </Select>
            </FormControl> */}
                <div className="dashboardBoxVal">
                    <Box className="dashInnerBoxWrap">
                        <Typography className="dashBoxHeading">
                            Manuscript Processed
                        </Typography>
                        <Typography className="dashBoxVal">
                            {/* {dashboardData.totalCount || 'Loading...'} */}
                            {dashboardData.totalCount !== undefined && dashboardData.totalCount !== null
                                ? dashboardData.totalCount
                                : 'Loading...'}
                            {/* 368 */}
                        </Typography>
                    </Box>
                    <Box className="dashInnerBoxWrap">
                        <Typography className="dashBoxHeading">
                            Intensive Digital Edits Count
                        </Typography>
                        <Typography className="dashBoxVal">
                            {dashboardData.intensiveCompleteCount !== undefined && dashboardData.intensiveCompleteCount !== null
                                ? dashboardData.intensiveCompleteCount
                                : 'Loading...'}
                            {/* 153 */}
                        </Typography>
                    </Box>
                    <Box className="dashInnerBoxWrap">
                        <Typography className="dashBoxHeading">
                            Avg. Language Assessment Score
                        </Typography>
                        <Typography className="dashBoxVal">
                            {dashboardData.averageQuality !== undefined && dashboardData.averageQuality !== null
                                ? dashboardData.averageQuality
                                : 'Loading...'}%
                            {/* 91.23% */}
                            {/* {dashboardData.averageQuality || 'Loading...'}% */}
                        </Typography>
                    </Box>
                </div>
                <div className="fileProcessedGraph">
                    <div className="mrChartWrap">
                        <h3 className="manuscriptGraph">
                            Manuscript Report
                        </h3>
                        <BarChart
                            series={[
                                { data: [dashboardData.filesProcessedCount] || [0], color: '#3F52A3' },
                                { data: [dashboardData.intensiveEditsCount] || [0], color: '#F78F1E' }
                                // { data: [33, 74, 58, 39, 75, 90, 40], color: '#3F52A3' },
                                // { data: [12, 28, 20, 12, 32, 39, 10], color: '#F78F1E' }
                                // { data: [368], color: '#3F52A3' },
                                // { data: [153], color: '#F78F1E' }
                            ]}
                            width={500}
                            height={350}
                            xAxis={[
                                {
                                    scaleType: 'band',
                                    categoryGapRatio: 0.9,
                                    barGapRatio: 0,
                                    type: 'value',
                                    position: 'bottom',
                                    // data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
                                    data: ['Total'],
                                    show: true,
                                    name: 'X-Axis Title',
                                },
                            ]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                        <div className="barchartInfo">
                            <div className="seperateInfo">
                                <div className="infoDot1" ></div>
                                <span>Articles Processed</span>
                            </div>
                            <div className="seperateInfo">
                                <div className="infoDot2" ></div>
                                <span>Intensive Digital Edits Initiated</span>
                            </div>
                        </div>
                    </div>
                    <div className="laqChartWrap">
                        <h3 className="manuscriptGraph mt-1">
                            Language Assessment Quality
                        </h3>
                        <BarChart
                            className="new"
                            series={[
                                { data: [dashboardData.passedArticlesCount] || [0], color: '#09ae2d' },
                                { data: [dashboardData.failedArticlesCount] || [0], color: '#c62026' }                                
                                // { data: [30, 69, 50, 30, 70, 79, 40], stack: 'A', color: '#09ae2d' },
                                // { data: [3, 5, 8, 9, 5, 11, 0], stack: 'A', color: '#c62026' }
                                // { data: [327], stack: 'A', color: '#09ae2d' },
                                // { data: [41], stack: 'A', color: '#c62026' }

                            ]}
                            width={500}
                            height={350}
                            xAxis={[
                                {
                                    scaleType: 'band',
                                    categoryGapRatio: 0.9,
                                    barGapRatio: 0,
                                    fontStyle: 'bold',
                                    type: 'value',
                                    position: 'bottom',
                                    data: ['Total'],
                                    show: true,
                                    name: 'X-Axis Title',
                                },
                            ]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                        <div className="barchartInfo">
                            <div className="seperateInfo">
                                <div className="infoDot3" ></div>
                                <span>Pass</span>
                            </div>
                            <div className="seperateInfo">
                                <div className="infoDot4" ></div>
                                <span>Fail</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


