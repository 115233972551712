
import React from 'react';

const MySvgFile = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
<g id="mdi:report-bar">
<path id="Vector" d="M16.5 16.25H1.5V2.75H3V14.75H4.5V8H7.5V14.75H9V5H12V14.75H13.5V11H16.5V16.25Z"/>
</g>
</svg>
  );
};

export default MySvgFile;
