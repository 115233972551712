import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import integraLogo from "../icons/authorPilot_logo.png";
import integraLogoShort from "../icons/integra_short.png";
import MenuOpen from "../icons/menu_open.svg";
import MenuClose from "../icons/menu_close.svg";
import DashboardIcon from "../icons/dashboard_icon.jsx";
import UserDropdown from "./UserDropdown";
import ReportIcon from "../icons/report_icon.jsx";
import IntegraIcon from "../icons/integra_black.png";
import ManuscriptIcon from "../icons/manuscriptIcon.jsx";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ManuscriptReadiness from "./ManuscriptReadiness";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import { useAuth0 } from '@auth0/auth0-react';
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
    const location = useLocation();
    const { search } = location;
    // useEffect(() => {
    //     const params = new URLSearchParams(search);
    //     const autoLoginValue = params.get('autologin');
    //     console.log(autoLoginValue, "autoLoginValue")
    //     const authenticateUser = async () => {
    //         if (!isAuthenticated && autoLoginValue) {
    //             try {
    //                 await loginWithRedirect();
    //             } catch (error) {
    //                 console.error('Authentication error:', error);
    //             }
    //         } else {
    //             console.log(isAuthenticated, "isauth")
    //         }
    //     };

    //     authenticateUser();
    // }, []);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleNavigation = (path, event) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(path);
    };

    return (
        <Box sx={{ display: 'flex' }} className="sideDrawerWrap">
            <CssBaseline />
            <AppBar position="fixed" open={open} className="appBarClass">
                <Toolbar className="toolBarClass">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        {open ? (
                            <img src={MenuOpen} className="menuOpenClass" alt="IntegraLogo" title="Side Navbar Close" />
                        ) : (
                            <img src={MenuClose} className="menuCloseClass" alt="IntegraLogo" title="Side Navbar Open" />
                        )}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" className="drawerClass" open={open}>
                <DrawerHeader className="sideNavHeader">
                    <div className="logoClass">
                        {open ? (
                            <img src={integraLogo} alt="IntegraLogo" className="fullLogo" />
                        ) : (
                            <img src={integraLogoShort} className="LogoShort" alt="IntegraLogo" />
                        )}
                    </div>
                </DrawerHeader>
                <Divider />
                <div className="sideNavWrap">
                    <List>
                        {[
                            {
                                title: "",
                                items: [
                                    {
                                        text: "Dashboard",
                                        icon: (
                                            // <img
                                            //     src={DashboardIcon}
                                            //     alt="Dashboard"
                                            //     style={{ width: "100%", height: "100%" }}
                                            // />
                                            <Tooltip title="Dashboard"> {/* Add Tooltip here */}
                                                <DashboardIcon />
                                            </Tooltip>
                                        ),
                                        path: "/dashboard",
                                    },
                                ],
                            },
                            {
                                title: "QUALITY CHECK",
                                items: [
                                    {
                                        text: "Manuscript Readiness",
                                        icon: (
                                            // <img
                                            //     className='new'
                                            //     src={manuscriptIcon}
                                            //     alt="ManuscriptReadiness"
                                            //     style={{ width: "100%", height: "100%" }}
                                            // />
                                            <Tooltip title="Manuscript Readiness"> {/* Add Tooltip here */}
                                                <ManuscriptIcon />
                                            </Tooltip>
                                        ),
                                        path: "/manuscript-readiness",
                                    },
                                ],
                            },
                            {
                                title: "ANALYTICS",
                                items: [
                                    {
                                        text: "Reports",
                                        icon: (
                                            // <img
                                            //     src={ReportIcon}
                                            //     alt="Report"
                                            //     style={{ width: "100%", height: "100%" }}
                                            // />
                                            <Tooltip title="Reports"> {/* Add Tooltip here */}
                                                <ReportIcon />
                                            </Tooltip>
                                        ),
                                        path: "/reports",
                                    },
                                ],
                            },
                        ].map((group, index, array) => (
                            <React.Fragment key={group.title}>
                                {open && (
                                    <Typography
                                        className="leftPanelTitles"
                                        variant="subtitle2"
                                    >
                                        {group.title}
                                    </Typography>
                                )}
                                {group.items.map((item) => (
                                    <React.Fragment key={item.text}>
                                        <ListItem disablePadding>
                                            <ListItemButton className="singleLinkOption"
                                                onClick={(event) => handleNavigation(item.path, event)}>
                                                <div className={`${location.pathname === item.path ? 'activeLink' : ''} innerListBtnWrap `}>
                                                    {item.icon && (
                                                        <ListItemIcon className={open ? ("listItemIcon") : ("listItemIconClose")}>
                                                            {item.icon}
                                                        </ListItemIcon>
                                                    )}
                                                    {item.text && (
                                                        <ListItemText
                                                            className="listItemName"
                                                            primary={open ? item.text : ''}
                                                        />
                                                    )}
                                                </div>

                                            </ListItemButton>
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                                {index < array.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </div>
                <Divider />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <UserDropdown />
                <DrawerHeader />
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/manuscript-readiness" element={<ManuscriptReadiness />} />
                    <Route path="/reports" element={<Reports />} />
                </Routes>
            </Box>
            <p className="footerWrap"><span className="footerTextWrap">Powered by <span className="poweredImgWrap"> <img src={IntegraIcon} alt="Integra Logo" /></span> | All Rights Reserved<span></span></span></p>
        </Box>
    );
}
