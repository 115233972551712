import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react';
import { redirect } from 'react-router-dom';

const domain=process.env.REACT_APP_AUTH0_DOMAIN;
const clientId=process.env.REACT_APP_AUTH0_CLIENT_ID
const root = ReactDOM.createRoot(document.getElementById('root'));
// const onRedirectCallback = (appState) => {
//   console.log("inside callback")
//   window.location.replace(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };
root.render(
  <Auth0Provider
  domain={domain}
  clientId={clientId}
  // onRedirectCallback={onRedirectCallback}
  authorizationParams={{
    // redirect_uri: window.location.origin,
    redirect_uri: "https://authorpilot.integra.co.in/manuscript-readiness",
    audience:"aigateway.integra.co.in"
  }}
  >
    <App />
  </Auth0Provider>
);

