
import React from 'react';

const MySvgFile = () => {
  return (
   <svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
<g id="material-symbols:dashboard">
<path id="Vector" d="M9.75 7.25V2.75H15.75V7.25H9.75ZM2.25 10.25V2.75H8.25V10.25H2.25ZM9.75 16.25V8.75H15.75V16.25H9.75ZM2.25 16.25V11.75H8.25V16.25H2.25Z"/>
</g>
</svg>
  );
};

export default MySvgFile;
