import React, { useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Snackbar,
    Select,
    Typography,
    Input,
    Box,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    Divider,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Reports = () => {
    const location = useLocation();
    const htmlString = location.state?.htmlString || '';
    const [repoType, setRepoType] = useState(location.state?.assessmentType || '');
    const [articleIdNotFound, setArticleIdNotFound] = useState(false);
    const [journalCode, setJournalCode] = useState(location.state?.journalCode || '');
    const [articleId, setArticleId] = useState(location.state?.articleId || '');
    const [htmlContent, setHtmlContent] = useState('');

    const [isApplyDisabled, setIsApplyDisabled] = useState(true);

    const assessmenthandleChange = (event) => {
        setRepoType(event.target.value);
        setArticleId('');
        setHtmlContent(''); // Clear HTML content when assessment type changes
        setIsApplyDisabled(true); // Disable Apply button
    };

    const articleIdhandleChange = (event) => {
        setArticleId(event.target.value);
        setIsApplyDisabled(!event.target.value); // Enable Apply button if article ID is not empty
    };

    const reportNameClick = async () => {
        if (!repoType || !articleId) return; // Check if assessment type and article ID are selected

        // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/db/reportDetails', {
        //     processId: articleId,
        // }, { withCredentials: true });
        const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/db/reportDetails', {
            processId: articleId,
        });
        if (response.data.length === 0) {
            setArticleIdNotFound(true);
            setArticleId('');
            setHtmlContent(''); // Clear HTML content when assessment type changes
            setIsApplyDisabled(true); // Disable Apply button
            return;
        }
        let fileName = response.data[0].FileName;
        let cleanedFileName = fileName.replace(/\.docx$/, '');
        let urlPath = repoType === 'lar' ? `${articleId}/${cleanedFileName}.html` : `${articleId}/${cleanedFileName}_autostructure.html`;

        // const viewReporesponse = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchFile', {
        //     processId: articleId,
        //     fileDownName: cleanedFileName,
        //     reportType:'mla',
        //     downType: 'HtmlDown',
        //     url: urlPath
        // }, { withCredentials: true });
        const viewReporesponse = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/fetchFile', {
            processId: articleId,
            fileDownName: cleanedFileName,
            reportType:'mla',
            downType: 'HtmlDown',
            url: urlPath
        });
        let serviceRes = viewReporesponse.data;
        let respbase64 = arrayBufferToBase64(serviceRes.data)
        const htmlString = base64ToHtml(respbase64);
        setHtmlContent(htmlString);
    }

    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    const base64ToHtml = (base64String) => {
        return atob(base64String);
    };

    return (
        <div className="reports-wrap">
            <h2>Reports</h2>
            <div className="reportSectionWrap">
                <Box p={2} className="reportSectionWrap">
                    <Grid container spacing={2} className="reportInnerWrap">
                        <Grid item xs={3} className="reportFirstGrid">
                            <span className="fieldLabel">Assessment Type*</span>
                            <FormControl className='selectFormContr'>
                                <Select
                                    value={repoType}
                                    onChange={assessmenthandleChange}
                                    displayEmpty
                                    required
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className='selectField'>
                                    <MenuItem disabled value="">
                                        <em>Select assessment report</em>
                                    </MenuItem>
                                    <MenuItem value={'lar'}>Language Assessment Report</MenuItem>
                                    <MenuItem value={'tar'}>Technical Assessment Report</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} className="reportThirdGrid">
                            <span className="fieldLabel">Article ID*</span>
                            <FormControl className='selectFormContr'>
                                <Input
                                    placeholder="Article ID"
                                    value={articleId}
                                    onChange={articleIdhandleChange}
                                    aria-label="Article ID"
                                    className='selectField'
                                    required
                                />
                            </FormControl>
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={articleIdNotFound}
                                autoHideDuration={4000}
                                onClose={() => setArticleIdNotFound(false)}
                            >
                                <MuiAlert
                                    variant="filled"
                                    className="fileExceedSnack"
                                    severity="warning"
                                >
                                    No report available for this Article ID.Please type a Valid Article ID.
                                </MuiAlert>
                            </Snackbar>
                        </Grid>
                        <Grid item xs={3} className="ApplyGrid">
                            <Button
                                variant="contained"
                                onClick={reportNameClick}
                                className={isApplyDisabled ? "ApplyGridBtnDisable" : "ApplyGridBtn"}
                                disabled={isApplyDisabled}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Divider />
            <div className="reportViewSection">
                <div className="reportViewClickWrap">
                    {ReactHtmlParser(htmlString)}
                </div>
                <div className="reportViewSearchWrap">
                    {ReactHtmlParser(htmlContent)}
                </div>
            </div>
        </div>
    );
};

export default Reports;
